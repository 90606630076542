import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import clockIcon from '../icons/clock-icon.png';
import thumbsUpIcon from '../icons/thumbs-up-icon.png';
import hammerIcon from '../icons/hammer-icon.png';
import guaranteeIcon from '../icons/guarantee-icon.png';
import specialistsIcon from '../icons/specialists-icon.jpg';
import markIcon from '../icons/mark-icon.png';

//

const cities = {
    mozhaisk: "Можайскому району и окрестностям",
    ruza: "Рузскому району и окрестностям",
    odincovo: "Одинцовскому району и окрестностям",
    ramenskoe: "Раменскому району и окрестностям",
    // Добавь другие города по необходимости
};

const citiesRedirect = {
    mozhaisk: "Можайский район",
    ruza: "Рузский район",
    odincovo: "Одинцовский район",
    ramenskoe: "Раменский район",
    // Добавь другие города по необходимости
};

const Stroybrigadamo = ({ phone_number }) => {
    const { city } = useParams();
    const navigate = useNavigate();
    const area = cities[city];
    const phone_number_link = "tel:"+phone_number;

    // Используем useEffect для перенаправления в случае, если город не найден
    useEffect(() => {
        if (city && !area) {
            navigate("/not-found");
        }
    }, [city, area, navigate]);

    if (city && !area) {
        return null; // Пока navigate сработает, возвращаем null
    }

    const handleRegionChange = (event) => {
        navigate(`/${event.target.value}`);
    }

    return (
        <div className="stroybrigadamo">
            <header>
                <h1>СТРОИТЕЛЬНАЯ БРИГАДА</h1>
                <p><strong>Мы выполняем строительные работы по разным районам. Выберите свой:<br />{area}</strong></p>
                <section className="location">
                    <select id="region-select" onChange={handleRegionChange}>
                        {Object.keys(citiesRedirect).map(key => (
                            <option value={key} key={key}>{citiesRedirect[key]}</option>
                        ))}
                    </select>
                    <button id="go-button" onClick={() => navigate(`/${document.getElementById('region-select').value}`)}>Перейти</button>
                </section>
                <div className="phone-number"><strong>{phone_number}</strong></div>
            </header>
            
            <div className="container">
                <p className="description">
                    Согласуем время и место <strong>БЕСПЛАТНОГО</strong> выезда мастера, сообщим <strong>ЦЕНЫ</strong> материалов
                </p>
                <p className="description">
                    Общение с мастером, <strong>НАПРЯМУЮ, БЕЗ ПОСРЕДНИКОВ.</strong>
                </p>
                <p className="description">
                    В нашей бригаде только <strong>СЛАВЯНЕ</strong>
                </p>
                <p className="description">
                    Работаем по <strong>ДОГОВОРУ</strong>
                </p>
                <a href={phone_number_link}>
                    <button>Позвонить</button>
                </a>
                
                <div className="section">
                    <h2>Главное о нас</h2>
                </div>

                <div className="main-about-us">
                    <div className="main-about-us-text">
                        <p>Наши специалисты готовы профессионально решить задачи по ремонту и строительству в кратчайшие сроки с комфортными для вас условиями.</p>
                        <ul>
                            <li>Используем свои материалы;</li>
                            <li>Выезд замерщика бесплатно;</li>
                            <li>Работаем по старым ценам.</li>
                        </ul>
                    </div>
                    <div className="main-about-us-image">
                        <img src={specialistsIcon} alt="Главное о нас" className="main-about-us-icon" />
                    </div>
                </div>
    
                <div className="section">
                    <h2>Выполняем различные виды строительных работ</h2>
                    <div className="columns">
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Наружные работы</h3>
                                <p>заборы, крыши, навесы, беседки, фундамент, печи, сайдинг, отмостка, плитка, брусчатка, хозблоки, септик, стяжка, и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Внутренние работы</h3>
                                <p>туалет, душ, отопление, водоснабжение, сантехника, натяжные потолки, пластиковые окна, печи и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Стройка с нуля</h3>
                                <p>Дома и бани с нуля</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="advantages">
                    <h2>Наши преимущества</h2>
                    <div className="advantages-list">
                        <div className="advantage-item">
                            <img src={clockIcon} alt="Скорость" className="advantage-icon" />
                            <h3>Скорость</h3>
                            <p>Максимально короткие сроки выполнения работ</p>
                        </div>
                        <div className="advantage-item">
                            <img src={thumbsUpIcon} alt="Удобство" className="advantage-icon" />
                            <h3>Удобство</h3>
                            <p>Не нужно тратить время на поиск и доставку материалов</p>
                        </div>
                        <div className="advantage-item">
                            <img src={hammerIcon} alt="Качество" className="advantage-icon" />
                            <h3>Качество</h3>
                            <p>В нашей команде работают профессиональные мастера</p>
                        </div>
                        <div className="advantage-item">
                            <img src={guaranteeIcon} alt="Гарантия" className="advantage-icon" />
                            <h3>Гарантия</h3>
                            <p>Работаем по договору с гарантией, предоставляем скидки пенсионерам</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <h3>Оставьте заявку и мы ответим на ваши вопросы</h3>
                <a href={phone_number_link}>
                    <button>Оставить заявку</button>
                </a>
            </footer>
        </div>
    );
}

export default Stroybrigadamo;