// NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import notFoundIcon from '../icons/not-found-icon.png';

const citiesRedirect = {
    mozhaisk: "Можайский район",
    ruza: "Рузский район",
    odincovo: "Одинцовский район",
    ramenskoe: "Раменский район",
    // Добавь другие города по необходимости
};

const NotFound = ({ phone_number }) => {
    const navigate = useNavigate();

    const handleRegionChange = (event) => {
        navigate(`/${event.target.value}`);
    }

    return (
        <div>
            <h2>Такой страницы нет, но можно перейти на страницу интересующего района:</h2>
            <section className="location">
                <select id="region-select" onChange={handleRegionChange}>
                    {Object.keys(citiesRedirect).map(key => (
                        <option value={key} key={key}>{citiesRedirect[key]}</option>
                    ))}
                </select>
                <button id="go-button" onClick={() => navigate(`/${document.getElementById('region-select').value}`)}>Перейти</button>
            </section>
            <h2>Или можно связаться с нашим оператором по номеру: {phone_number}</h2>
            <img src={notFoundIcon} alt="Такой страницы нет" className="notFound-icon" />
        </div>
    );
};

export default NotFound;