import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import clockIcon from '../icons/clock-icon.png';
import thumbsUpIcon from '../icons/thumbs-up-icon.png';
import hammerIcon from '../icons/hammer-icon.png';
import guaranteeIcon from '../icons/guarantee-icon.png';
import specialistsIcon from '../icons/specialists-icon.jpg';
import markIcon from '../icons/mark-icon.png';

//test commit jino1

const cities = {
    mozhaisk: "Можайскому району и окрестностям",
    ruza: "Рузскому району и окрестностям",
    odincovo: "Одинцовскому району и окрестностям",
    ramenskoe: "Раменскому району и окрестностям",
    // Добавь другие города по необходимости
};

const Stroybrigadamo = ({ phone_number }) => {
    const { city } = useParams();
    const navigate = useNavigate();
    const area = cities[city];
    const phone_number_link = "tel:"+phone_number;

    // Используем useEffect для перенаправления в случае, если город не найден
    useEffect(() => {
        if (!area) {
            navigate("/not-found");
        }
    }, [area, navigate]);

    // Состояния для хранения значений площади, высоты и результатов расчетов
    const [areaValue, setArea] = useState('');
    const [height, setHeight] = useState('');
    const [coolingPower, setCoolingPower] = useState('');
    const [electricPower, setElectricPower] = useState('');

    // useEffect для выполнения расчетов при изменении площади или высоты
    useEffect(() => {
        if (areaValue && height) {
            const Q = parseFloat(areaValue) * parseFloat(height) * 0.04;
            const P = Q / 3;
            setCoolingPower(Q.toFixed(2));
            setElectricPower(P.toFixed(2));
        }
    }, [areaValue, height]);

    if (!area) {
        return null; // Пока navigate сработает, возвращаем null
    }

    return (
        <div className="stroybrigadamo">
            <header>
                <h1>МОНТАЖ КОНДИЦИОНЕРОВ</h1>
                <p>Выполняем ремонт и установку систем кондиционирования по:<br/>{area}<br/>Звоните по телефону:</p>
                <div className="phone-number"><strong>{phone_number}</strong></div>
            </header>
            <div className="container">
                <div className="description">
                    <p>
                        Устанавливаем и налаживаем <strong>ДОМАШНИЕ КОНДИЦИОНЕРЫ</strong> и <strong>СПЛИТ-СИСТЕМЫ</strong>
                    </p>
                    <p>
                        Производим <strong>РЕМОНТ</strong> и <strong>ОБСЛУЖИВАНИЕ </strong> кондиционеров <strong> ПОД КЛЮЧ</strong>
                    </p>
                    <p>
                        У вас<strong> ПРОМЫШЛЕННАЯ СПЛИТ-СИСТЕМА?</strong> Обязательно поможем!
                    </p>
                    <p>
                        Общение с мастером, <strong>НАПРЯМУЮ, БЕЗ ПОСРЕДНИКОВ</strong>
                    </p>
                    <p>
                        В нашей бригаде только <strong>СЛАВЯНЕ</strong>
                    </p>
                    
                    <a href={phone_number_link}>
                        <button>Позвонить</button>
                    </a>
                </div>

                <div className="section">
                    <h2>Рассчитать онлайн мощность домашнего кондиционера</h2>
                    <div className="calculator">
                        <label>
                            Общая площадь помещения (S) м²:
                                <input
                                    type="number"
                                    className="input-large"
                                    value={areaValue}
                                    onChange={(e) => setArea(e.target.value)}
                                    required
                                />
                        </label>
                        <br />
                        <label>
                            Высота потолков (h) м:
                            <input
                                type="number"
                                className="input-large"
                                value={height}
                                onChange={(e) => setHeight(e.target.value.replace(',', '.'))}
                                step="0.05"
                                required
                            />
                        </label>
                        <h2>Результаты расчета:</h2>
                        <div className="columns-calculator">
                            <div className="list">
                                <table className="calculation-table">
                                    <thead>
                                        <tr>
                                            <th>Мощность охлаждения (Q), кВт</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(coolingPower || "0,00").toString().replace('.', ',')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="list">
                                <table className="calculation-table">
                                    <thead>
                                        <tr>
                                            <th>Электрическая мощность (P), кВт</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(electricPower || "0,00").toString().replace('.', ',')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <h2>Главное о нас</h2>
                </div>
                <div className="main-about-us">
                    <div className="main-about-us-text">
                        <p>Наши специалисты готовы профессионально установить и подключить кондиционер в кратчайшие сроки с комфортными для вас условиями.</p>
                        <ul>
                            <li>Используем свои материалы;</li>
                            <li>Выезд специалиста бесплатно;</li>
                            <li>Работаем по старым ценам.</li>
                        </ul>
                    </div>
                    <div className="main-about-us-image">
                        <img src={specialistsIcon} alt="Главное о нас" className="main-about-us-icon" />
                    </div>
                </div>
    
                <div className="section">
                    <h2>Выполняем различные виды работ с климатическими системами</h2>
                    <div className="columns">
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Частные дома, квартиры</h3>
                                <p>Установка, наладка, обслуживание и ремонт домашних сплит-систем</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Промышленные системы</h3>
                                <p>Расчёт и проектирование промышленных систем для зданий</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Администратиные здания</h3>
                                <p>Работаем с магазинами, торговыми центрами и т.д.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="advantages">
                    <h2>Наши преимущества</h2>
                    <div className="advantages-list">
                        <div className="advantage-item">
                            <img src={clockIcon} alt="Скорость" className="advantage-icon" />
                            <h3>Скорость</h3>
                            <p>Максимально короткие сроки выполнения работ</p>
                        </div>
                        <div className="advantage-item">
                            <img src={thumbsUpIcon} alt="Удобство" className="advantage-icon" />
                            <h3>Удобство</h3>
                            <p>Не нужно тратить время на поиск и доставку материалов</p>
                        </div>
                        <div className="advantage-item">
                            <img src={hammerIcon} alt="Качество" className="advantage-icon" />
                            <h3>Качество</h3>
                            <p>В нашей команде работают профессиональные мастера</p>
                        </div>
                        <div className="advantage-item">
                            <img src={guaranteeIcon} alt="Гарантия" className="advantage-icon" />
                            <h3>Гарантия</h3>
                            <p>Работаем по договору с гарантией, предоставляем скидки пенсионерам</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <h3>Оставьте заявку и мы ответим на ваши вопросы</h3>
                <a href={phone_number_link}>
                    <button>Оставить заявку</button>
                </a>
            </footer>
        </div>
    );
}

export default Stroybrigadamo;