// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Stroybrigadamo from './components/stroybrigadamo';
import NotFound from "./components/NotFound"; 
import MainStroybrigadamo from './components/mainStroybrigadamo';
import LeedWorkers from './components/LeedWorkers';
import Conditioning from './components/conditioning';
import PokosTravy from './components/pokosTravy';
const phone_number = "+79680795312"
//

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<MainStroybrigadamo phone_number={phone_number}/>} />
            <Route path="/not-found" element={<NotFound phone_number={phone_number}/>} />
            <Route path="/:city" element={<Stroybrigadamo phone_number={phone_number}/>} />
            <Route path="/recruitment/:city" element={<LeedWorkers phone_number={phone_number} />} />
            <Route path="/:city/conditioning" element={<Conditioning phone_number={phone_number} />} />
            <Route path="/pokos-travy" element={<PokosTravy phone_number={phone_number} />} />
            {/* Добавьте другие маршруты по необходимости */}
        </Routes>
    </Router>
);

export default App;


/*function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/:city" element={<Stroybrigadamo />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/" element={<div>Добро пожаловать на наш сайт!</div>} exact />
                </Routes>
            </div>
        </Router>
    );
}*/