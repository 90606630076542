import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import clockIcon from '../icons/clock-icon.png';
import thumbsUpIcon from '../icons/thumbs-up-icon.png';
import hammerIcon from '../icons/hammer-icon.png';
import guaranteeIcon from '../icons/guarantee-icon.png';
import specialistsIcon from '../icons/specialists-icon.jpg';
import markIcon from '../icons/mark-icon.png';

//test
const cities = {
    moskovskayaOblast: {
        mozhaisk: "Можайскому району и окрестностям",
        ruza: "Рузскому району и окрестностям",
        odincovo: "Одинцовскому району и окрестностям",
        ramenskoe: "Раменскому району и окрестностям",
        istra: "Истринскому району и окрестностям",         // Добавлен Истринский район
        volokolamsk: "Волоколамскому району и окрестностям", // Добавлен Волоколамский район
        narofominsk: "Наро-Фоминскому району и окрестностям" // Добавлен Наро-Фоминский район
        // Добавь другие районы Московской области по необходимости
    },
    volgogradskayaOblast: {
        volgograd: "Волгограду и окрестностям",
        volzhskiy: "Волжскому району и окрестностям",
        kamyshin: "Камышинскому району и окрестностям",
        mikhaylovka: "Михайловскому району и окрестностям",
        uryupinsk: "Урюпинскому району и окрестностям"
        // Добавь другие районы Волгоградской области по необходимости
    }
    // Добавь другие области по необходимости
};

const Stroybrigadamo = ({ phone_number }) => {
    const { city } = useParams();
    const navigate = useNavigate();

    let area;
    let foundOblast = null; // Добавили переменную для области

    for (const oblast in cities) {
        if (cities[oblast][city]) {
            area = cities[oblast][city];
            foundOblast = oblast;
            break; // выходим из цикла, как только нашли нужный город
        }
    }

    if (foundOblast === 'volgogradskayaOblast') {
        phone_number = "+79680795312";
    }

    const phone_number_link = "tel:"+phone_number;

    // Используем useEffect для перенаправления в случае, если город не найден
    useEffect(() => {
        if (!area) {
            navigate("/not-found");
        }
    }, [area, navigate]);

    if (!area) {
        return null; // Пока navigate сработает, возвращаем null
    }


    
    return (
        <div className="stroybrigadamo">
            <header>
                <h1>СТРОИТЕЛЬНАЯ БРИГАДА</h1>
                <p>Выполняем строительные работы по:<br/>{area}<br/>Звоните по телефону:</p>
                <div className="phone-number"><strong>{phone_number}</strong></div>
            </header>
            <div className="container">
                <div className="description">
                    <p>
                        Согласуем время и место <strong>БЕСПЛАТНОГО</strong> выезда мастера, сообщим <strong>ЦЕНЫ</strong> материалов
                    </p>
                    <p>
                        Общение с мастером, <strong>НАПРЯМУЮ, БЕЗ ПОСРЕДНИКОВ.</strong>
                    </p>
                    <p>
                        В нашей бригаде только <strong>СЛАВЯНЕ</strong>
                    </p>
                    <p>
                        Работаем по <strong>ДОГОВОРУ</strong>
                    </p>
                    <a href={phone_number_link}>
                        <button>Позвонить</button>
                    </a>
                    
                    <div className="section">
                        <h2>Главное о нас</h2>
                    </div>
                </div>

                <div className="main-about-us">
                    <div className="main-about-us-text">
                        <p>Наши специалисты готовы профессионально решить задачи по ремонту и строительству в кратчайшие сроки с комфортными для вас условиями.</p>
                        <ul>
                            <li>Используем свои материалы;</li>
                            <li>Выезд замерщика бесплатно;</li>
                            <li>Работаем по старым ценам.</li>
                        </ul>
                    </div>
                    <div className="main-about-us-image">
                        <img src={specialistsIcon} alt="Главное о нас" className="main-about-us-icon" />
                    </div>
                </div>
    
                <div className="section">
                    <h2>Выполняем различные виды строительных работ</h2>
                    <div className="columns">
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Наружные работы</h3>
                                <p>заборы, крыши, навесы, беседки, фундамент, печи, сайдинг, отмостка, плитка, брусчатка, хозблоки, септик, стяжка, и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Внутренние работы</h3>
                                <p>туалет, душ, отопление, водоснабжение, сантехника, натяжные потолки, пластиковые окна, печи и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Стройка с нуля</h3>
                                <p>Дома и бани с нуля</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="advantages">
                    <h2>Наши преимущества</h2>
                    <div className="advantages-list">
                        <div className="advantage-item">
                            <img src={clockIcon} alt="Скорость" className="advantage-icon" />
                            <h3>Скорость</h3>
                            <p>Максимально короткие сроки выполнения работ</p>
                        </div>
                        <div className="advantage-item">
                            <img src={thumbsUpIcon} alt="Удобство" className="advantage-icon" />
                            <h3>Удобство</h3>
                            <p>Не нужно тратить время на поиск и доставку материалов</p>
                        </div>
                        <div className="advantage-item">
                            <img src={hammerIcon} alt="Качество" className="advantage-icon" />
                            <h3>Качество</h3>
                            <p>В нашей команде работают профессиональные мастера</p>
                        </div>
                        <div className="advantage-item">
                            <img src={guaranteeIcon} alt="Гарантия" className="advantage-icon" />
                            <h3>Гарантия</h3>
                            <p>Работаем по договору с гарантией, предоставляем скидки пенсионерам</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <h3>Позвоните нам и мы ответим на ваши вопросы</h3>
                <a href={phone_number_link}>
                    <button>Позвонить</button>
                </a>
            </footer>
        </div>
    );
}

export default Stroybrigadamo;