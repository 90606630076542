// NotFound.js
import React from 'react';
import '../App.css';
import clockIcon from '../icons/clock-icon.png';
import thumbsUpIcon from '../icons/thumbs-up-icon.png';
import hammerIcon from '../icons/hammer-icon.png';
import guaranteeIcon from '../icons/guarantee-icon.png';
import pokos from '../icons/pokos.png';
import markIcon from '../icons/mark-icon.png';





const PokosTravy = ({ phone_number }) => {
    const phone_number_link = "tel:"+phone_number;

    // Используем useEffect для перенаправления в случае, если город не найден


    return (
        <div>
            <header>
                <h1>Покос травы</h1>
                <p>Выполняем покос травы только в Можайском и Рузском районах</p>
                <div className="phone-number"><strong>{phone_number}</strong></div>
            </header>
            <div className="container">
                <div className="description">
                    <p>
                        Согласуем время и место для выезда рабочего, сообщим <strong>ЦЕНЫ</strong> работ
                    </p>
                    <p>
                        Общение с рабочим, <strong>НАПРЯМУЮ, БЕЗ ПОСРЕДНИКОВ.</strong>
                    </p>
                    <p>
                        В нашей бригаде только <strong>СЛАВЯНЕ</strong>
                    </p>
                    <p>
                        Работаем по <strong>ДОГОВОРУ</strong>
                    </p>
                    <a href={phone_number_link}>
                        <button>Позвонить</button>
                    </a>
                    
                    <div className="section">
                        <h2>Главное о нас</h2>
                    </div>
                </div>

                <div className="main-about-us">
                    <div className="main-about-us-text">
                        <p>Наши специалисты готовы профессионально решить задачи по покосу травы в кратчайшие сроки с комфортными для вас условиями.</p>
                        <ul>
                            <li>Косим ХОРОШО, не халтурим;</li>
                            <li>Внимательно слушаем клиента;</li>
                            <li>Работаем по старым ценам.</li>
                        </ul>
                    </div>
                    <div className="main-about-us-image">
                        <img src={pokos} alt="Главное о нас" className="main-about-us-icon" />
                    </div>
                </div>
    
                <div className="section">
                    <h2>Выполняем несколько видов работ по покосу травы</h2>
                    <div className="columns">
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Покос газона</h3>
                                <p>Так же аккуратно относимся к цветам, лучше не скосить и спросить, чем скосить и пожалеть</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Покос бурьяна</h3>
                                <p>Поможем разобраться не только с бурьяном, но разобраться с труднодоступными для покоса местами на участке</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Уборка скошенных участков</h3>
                                <p>После выполнения работ, соберем всю траву в одно место, куда скажите</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="advantages">
                    <h2>Наши преимущества</h2>
                    <div className="advantages-list">
                        <div className="advantage-item">
                            <img src={clockIcon} alt="Скорость" className="advantage-icon" />
                            <h3>Скорость</h3>
                            <p>Максимально короткие сроки выполнения работ</p>
                        </div>
                        <div className="advantage-item">
                            <img src={thumbsUpIcon} alt="Удобство" className="advantage-icon" />
                            <h3>Удобство и прозрачность</h3>
                            <p>Мы не посредники, сами договорились, сами приехали и покосили</p>
                        </div>
                        <div className="advantage-item">
                            <img src={hammerIcon} alt="Качество" className="advantage-icon" />
                            <h3>Качество</h3>
                            <p>В нашей команде только профессиональные рабочие</p>
                        </div>
                        <div className="advantage-item">
                            <img src={guaranteeIcon} alt="Гарантия" className="advantage-icon" />
                            <h3>Гарантия</h3>
                            <p>Работаем по договору с гарантией, предоставляем скидки пенсионерам</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <h3>Позвоните нам и мы ответим на ваши вопросы</h3>
                <a href={phone_number_link}>
                    <button>Позвонить</button>
                </a>
            </footer>
        </div>
    );
};

export default PokosTravy;