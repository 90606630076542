// NotFound.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import specialistsIcon from '../icons/specialists-icon.jpg';
import markIcon from '../icons/mark-icon.png';
const cities = {
    mozhaisk: "Можайском районе и окрестностях",
    ruza: "Рузском районе и окрестностях",
    odincovo: "Одинцовском районе и окрестностях",
    ramenskoe: "Раменском районе и окрестностях",
    // Добавь другие города по необходимости
};


const LeedWorkers = ({ phone_number }) => {
    const { city } = useParams();
    const navigate = useNavigate();
    const area = cities[city];
    const phone_number_link = "tel:"+phone_number;

    // Используем useEffect для перенаправления в случае, если город не найден
    useEffect(() => {
        if (!area) {
            navigate("/not-found");
        }
    }, [area, navigate]);

    if (!area) {
        return null; // Пока navigate сработает, возвращаем null
    }


    return (
        <div>
            <header>
                <h1>Найдем клиента для тебя или твоей бригады</h1>
                <p>Ищем мастеров или бригады в:<br/>{area}<br/>Звоните по телефону:</p>
                <div className="phone-number"><strong>{phone_number}</strong></div>
            </header>
            <div className="container">
            <div className="description">
                <p>
                    Присоединяйся к нашей команде и получай <strong>СТАБИЛЬНЫЕ ЗАКАЗЫ</strong> от клиентов.
                </p>
                <p>
                    Сотрудничество <strong>НАПРЯМУЮ С КЛИЕНТОМ, БЕЗ ПОСРЕДНИКОВ.</strong>
                </p>
                <p>
                    Мы ценим профессионализм и <strong>ОТВЕТСТВЕННОСТЬ</strong>.
                </p>
                <p>
                    Работаем  <strong>ПРОЗРАЧНО</strong> и обеспечиваем постоянным потоком клиентов.
                </p>
                <a href={phone_number_link}>
                    <button>Связаться с нами</button>
                </a>                  
                    <div className="section">
                        <h2>Главное о нас</h2>
                    </div>
                </div>

                <div className="main-about-us">
                    <div className="main-about-us-text">
                        <p>Присоединяйся к нашей команде и получай стабильные заказы на ремонт и строительство с комфортными условиями:</p>
                        <ul>
                            <li>Мгновенно передаем телефон клиента после обращения;</li>
                            <li>Заранее уточняем место и дату начала работ;</li>
                            <li>Отсеиваем звонки и заказы от посреднических фирм.</li>
                            <li>Расчет НАПРЯМУЮ с клиентом.</li>
                            <li>Мы не работодатель, мы надежный партнер.</li>
                        </ul>
                    </div>
                    <div className="main-about-us-image">
                        <img src={specialistsIcon} alt="Главное о нас" className="main-about-us-icon" />
                    </div>
                </div>
    
                <div className="section">
                    <h2>Наши клиенты ищут</h2>
                    <div className="columns">
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Наружные работы</h3>
                                <p>заборы, крыши, навесы, беседки, фундамент, печи, сайдинг, отмостка, плитка, брусчатка, хозблоки, септик, стяжка, и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Внутренние работы</h3>
                                <p>туалет, душ, отопление, водоснабжение, сантехника, натяжные потолки, пластиковые окна, печи и т.д.</p>
                            </div>
                        </div>
                        <div className="column-third">
                            <div className="list">
                                <img src={markIcon} alt="Галочка" className="mark-icon" />
                                <h3>Стройка с нуля</h3>
                                <p>Дома и бани с нуля</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <h3>Устраивает? Звони и получай клиентов напрямую</h3>
                <a href={phone_number_link}>
                    <button>Позвонить</button>
                </a>
            </footer>
        </div>
    );
};

export default LeedWorkers;